const DEFAULT_USER_IMAGE = `${process.env.REACT_APP_API_IMAGE}/image/favicon.ico`

const CURRENCY = '$'

const ROUTES = {
	subscribers: '/subscribers',
	sendEmail: '/send-email',
	subscribersAdd: '/subscribers/add',
	projects: '/series',
	projectAdd: '/series/create',
	episodes: '/episodes',
	episodesAdd: '/episodes/create',
	upcoming: '/upcoming',
}

export { DEFAULT_USER_IMAGE, CURRENCY, ROUTES }
